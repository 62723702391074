<template>
  <v-container class="mt-16">
    <div class="pl-10 pr-10">
      <h1>Dashboard</h1>
      <div class="d-flex mb-n8 mt-2">
        <v-spacer></v-spacer>
        <v-btn color="success" text @click="refresh" :loading="loading"
          ><v-icon class="mr-2">mdi-refresh</v-icon>Refresh</v-btn
        >
      </div>
      <v-divider></v-divider>
      <!-- <div v-if="ready" class="mt-5"> -->
      <!-- visible w/ other users except access level 4 -->
      <div v-if="this.user.access_level != 4">
        <tech-dashboard />
      </div>
      <div v-if="this.user.access_level == 4">
        <graphSummary />
      </div>
      <!-- total cards only visible at access level 4 or admin-->
      <div v-if="this.user.access_level >= 3">
        <total-sales-summary />
      </div>
      <!-- unpaid invoices and pickuped units
          only visible at access level 3 or w/ staffs
        -->
      <div v-if="this.user.access_level >= 3">
        <!-- <unpaid-invoices-summary /> -->
        <!-- <picked-up-units-summary /> -->
      </div>
      <!-- Resller's table visible only for access level 1 -->
      <div v-if="this.user.access_level == 1">
        <resellers-table />
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import graphSummary from "@/components/Dashboard/graphSummary.vue";
import TotalSalesSummary from "../components/Dashboard/totalSalesSummary.vue";
// import UnpaidInvoicesSummary from "../components/Dashboard/unpaidInvoicesSummary.vue";
// import PickedUpUnitsSummary from "../components/Dashboard/pickedUpUnitsSummary.vue";
import ResellersTable from "../components/Dashboard/resellersTable.vue";
import TechDashboard from "../components/Dashboard/techDashboard.vue";
export default {
  async created() {
    await this.check_if_exist();
    this.ready = true;
  },
  data() {
    return {
      loading: false,
      ready: false,
    };
  },
  computed: {
    ...mapGetters({
      default_server_url: "auth/default_server_url",
      user: "auth/user",
      items: "item/items",
      summary: "summary/summary",

      unit_status: "unit/unit_status",
      chart_of_accounts: "chart_of_account/chart_of_accounts",
    }),
  },
  methods: {
    async refresh() {
      this.loading = true;

      await this.get_items();
      this.loading = false;
    },
    print_invoice(item, display_price) {
      let type = item.is_quote == 1 ? "quote" : "invoice";
      var url =
        this.default_server_url +
        "/print" +
        "/" +
        type +
        "/" +
        item.id +
        "/" +
        display_price;
      window.open(url);
    },
    async check_if_exist() {
      // only for staff/clerk/admin assistant
      if (this.user.access_level >= 3) {
        if (!this.items) await this.get_items();

        if (this.chart_of_accounts.length <= 0)
          await this.get_chart_of_accounts();
        return;
      }
    },

    ...mapActions({
      get_items: "item/get_items",
      get_services: "service/get_services",
      get_summary: "summary/get_summary",
      get_yearly_summary: "summary/get_yearly_summary",
      get_chart_of_accounts: "chart_of_account/get_chart_of_accounts",
    }),
    signout() {
      this.$store.dispatch("auth/signout").then(() => {
        window.location = "/signin";
      });
    },
  },
  components: {
    graphSummary,
    TotalSalesSummary,
    // UnpaidInvoicesSummary,
    // PickedUpUnitsSummary,
    ResellersTable,
    TechDashboard,
  },
};
</script>

<style></style>
