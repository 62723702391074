<template>
  <div class="mt-10 mb-10">
    <div class="mt-2">
      <h2>Sales Performance</h2>
      <p class="overline">Sales summary from January - December, 2023</p>
      <!-- graphs -->
      <GChart
        v-if="ready"
        type="ColumnChart"
        :data="yearly_summary"
        :options="chartOptions"
      />
      <h2 v-else class="text-center display-1">Graph is Loading...</h2>
    </div>
    <!-- <div class="mt-5">
      <h2>Services Performance</h2>
      <p class="overline">Service summary from January - December, 2024</p>
      <GChart
        v-if="ready"
        type="ColumnChart"
        :data="yearly_service_summary"
        :options="chartOptions2"
      />
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { GChart } from "vue-google-charts/legacy";
export default {
  components: { GChart },
  async created() {
    if (!this.yearly_summary) await this.get_yearly_summary();
    this.ready = true;

    let response = await axios.get("/get_service_summary");
    this.yearly_service_summary = response.data;
  },
  computed: {
    ...mapGetters({
      yearly_summary: "summary/yearly_summary",
    }),
  },
  methods: {
    ...mapActions({
      get_yearly_summary: "summary/get_yearly_summary",
    }),
  },
  data() {
    return {
      ready: true,
      yearly_service_summary: null,
      chartOptions: {
        chart: {
          title: "Sales Performance",
          subtitle:
            "Sales/Item Sales,Service Revenue, Negoiated Service Revenue",
        },
      },
      chartOptions2: {
        chart: {
          title: "Service Performance",
          subtitle:
            "Sales/Item Sales,Service Revenue, Negoiated Service Revenue",
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
