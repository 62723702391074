<template>
  <div>
    <v-btn @click="dialog = true" small color="" class="mt-3">
      <v-icon class="mr-1">mdi-printer</v-icon>
      List of Sales</v-btn
    >
    <v-dialog v-model="dialog" persistent width="750">
      <v-card>
        <v-card-title>List of Sales</v-card-title>
        <v-card-text>
          <h3>Print per day</h3>
          <div class="d-flex">
            <v-select
              :items="days"
              label="Day"
              v-model="perDayForm.day"
              class="mr-3"
              prepend-icon="mdi-calendar"
            ></v-select>
            <v-select
              :items="months"
              item-text="text"
              item-value="value"
              label="Month"
              v-model="perDayForm.month"
              class="mr-3"
              prepend-inner-icon="mdi-calendar"
            ></v-select>
            <v-select
              :items="[2024, 2025, 2026, 2027]"
              item-text="text"
              item-value="value"
              label="Year"
              v-model="perDayForm.year"
              class="mr-3"
              prepend-inner-icon="mdi-calendar"
            ></v-select>
            <v-btn class="mt-3" small color="success" @click="printPerDaySale"
              >Print</v-btn
            >
          </div>
          <h3>Print from range</h3>
          <div v-if="form.radioSelection == 'items'" class="d-flex">
            <v-select
              label="Batch Import"
              :items="import_batches"
              item-text="batch_description"
              item-value="id"
              v-model="form.import_batch_id"
              class="mr-5"
            ></v-select>

            <custom-date-picker
              label="From Date"
              disableTime
              @setDateTime="setFromDate"
            />

            <custom-date-picker
              label="To Date"
              disableTime
              @setDateTime="setToDate"
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submit" :loading="loading"
            >Submit</v-btn
          >
          <v-btn @click="dialog = false"> Close</v-btn>
          <v-spacer></v-spacer>
          <p class="caption grey--text">printSalesSummaryModal.vue</p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomDatePicker from "../customDatePicker.vue";
import moment from "moment";
export default {
  components: { CustomDatePicker },
  data() {
    return {
      perDayForm: {
        day: 0,
        month: 0,
        year: 2024,
      },
      days: [],
      months: [
        { text: "January", value: 1 },
        { text: "February", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
      ],
      dialog: false,
      loading: false,
      form: {
        radioSelection: "items",
        fromDate: "",
        toDate: "",
        import_batch_id: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      import_batches: "importBatch/import_batches",
      default_server_url: "auth/default_server_url",
      user: "auth/user",
    }),
  },
  methods: {
    printPerDaySale() {
      let x = window.confirm("Proceed with your request?");
      if (x) {
        let formattedDate =
          this.perDayForm.year +
          "-" +
          this.perDayForm.month +
          "-" +
          this.perDayForm.day;
        let url =
          this.default_server_url +
          "/print_per_day_sales/" +
          formattedDate +
          "/" +
          this.user.branch_id;
        window.open(url);
      }
    },
    ...mapActions({
      get_import_batches: "importBatch/get_import_batches",
    }),
    changeSelection() {
      console.log(this.radioSelection);
    },
    submit() {
      let import_batch_id =
        this.form.radioSelection == "items" ? this.form.import_batch_id : 0;
      //   check if both date exists
      if (this.form.fromDate == "" || this.form.toDate == "")
        return alert("Please add all the dates required");
      let url =
        this.default_server_url +
        "/print_item_sales/" +
        this.form.fromDate +
        "/" +
        this.form.toDate +
        "/" +
        import_batch_id +
        "/" +
        this.form.radioSelection;
      window.open(url);
    },
    setFromDate(date) {
      this.form.fromDate = date;
    },
    setToDate(date) {
      this.form.toDate = date;
    },
  },
  created() {
    // generate days
    let x;
    for (x = 1; x <= 31; x++) {
      this.days.push(x);
    }
    let month = moment().month();
    let day = moment().date();
    let year = moment().year();
    this.perDayForm.month = month + 1;
    this.perDayForm.day = day;
    this.perDayForm.year = year;

    this.import_batches.unshift({
      id: "none",
      batch_description: "Print All",
    });
    if (this.import_batches.length < 2)
      this.get_import_batches().then(() => {});
  },
};
</script>

<style lang="scss" scoped></style>
